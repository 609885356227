import React from "react";

const HighlightCard = ({ imageUrl, sportsName }) => {
  return (
    <div className="bg-white p-4 md:pb-4 pb-0 h-auto flex flex-col items-center gap-3 mx-4 cursor-pointer transition-transform duration-300 transform hover:scale-105">
      <div className="">
        <img src={imageUrl} alt={sportsName} className="md:h-[200px] h-[175px] w-full" />
      </div>
      <div className="text-center font-allura md:text-5xl text-4xl text-red-800 font-normal capitalize">
        <p>{sportsName}</p>
      </div>
    </div>
  );
};

export default HighlightCard;
