import React from "react";
import { iit, sports } from "../../assests";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="bg-black opacity-90 px-12 pt-8 pb-2" id="contact">
      <div className=" flex md:flex-row flex-col gap-4 justify-between">
        <div className="flex flex-col items-center">
          <div className="text-white font-cabin text-2xl font-bold capitalize">
            QUICK LINKS
          </div>
          <div className="text-white font-cabin text-lg font-normal capitalize">
            <Link to="/about">about sangram</Link>
          </div>
          <div className="text-white font-cabin text-lg font-normal capitalize">
            <Link to="/events">All events</Link>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <div className="text-white font-cabin text-2xl font-bold capitalize">
            AN EVENT BY
          </div>
          <div className="flex gap-4 justify-center items-baseline">
            <div className="flex flex-col gap-4 items-center">
              <img src={iit} alt="" className="h-[80px]" />
              <p className="text-white text-center font-cabin text-sm font-normal capitalize">
                Indian Institute of technology, <br /> roorkee
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <img src={sports} alt="" className="h-[80px]" />
              <p className="text-white text-center font-cabin text-sm font-normal capitalize">
                Sports Council
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-white font-cabin text-xl font-bold capitalize">
            SANGRAM 2024
          </div>
          <div className="text-white font-cabin text-lg font-normal capitalize">
            Sports Council <br /> Indian Institute of technology, roorkee
          </div>
          <div className="text-white font-cabin text-lg italic font-normal capitalize">
            Roorkee - Haridwar Highway, Roorkee, <br /> Uttarakhand 247667
          </div>
        </div>
      </div>
      <div className="text-white font-cabin text-base font-normal  mt-4">
        For any issue please email us at{" "}
        <a href="mailto:sangram@iitr.ac.in">sangram@iitr.ac.in</a>
      </div>
    </div>
  );
};

export default Footer;
