import React, { useEffect, useState } from "react";
import Layout2 from "../components/layout/EventsLayout";
import RulesComponent from "../components/rules/Rules";
import SkeletonLoadingPage from "../components/Loading/Skeleton";
const AboutPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <SkeletonLoadingPage />;
  }
  return (
    <Layout2>
      <div>
        <RulesComponent
          heading="About"
          subheading="Sangram"
          paragraph={
            <>
              Sangram, a flagship three-day sports extravaganza of IIT Roorkee,
              strives to celebrate sports and provide a healthy environment to
              entertain and showcase your talent in sports in front of large
              numbers of pupils from some of the country's premier institutes.
              The festival enjoys the attendance of many people from IITs, NITs
              and other premier government and private colleges. The event is a
              gorgeous festival where people of different backgrounds from all
              around the college come to support and take part in occasions to
              support their fellow members, their teams and colleagues, and
              sometimes their friends too.
              <br /> <br />
              The event inculcates togetherness and acknowledges the spirit of
              sports and its importance in our lives. Engagement in Sangram can
              only be experienced, and its exuberance can't be reduced to mere
              words; the memories it brings about are enough to last a
              lifetime. 
              <br /> <br />
              Sangram also hosts many casual events like sports quizzes or
              marathon events, underscoring that it's not all about the physical
              sports events but also about enjoying and having the time of your
              life in general, whatever your preference. 
            </>
          }
        />
      </div>
      <div className="bg-[#e7e7e7] py-8">
        <RulesComponent
          heading="About"
          subheading="the institute"
          paragraph={
            <>
              With a legacy of over 175 years, the Indian Institute of
              Technology Roorkee is one of the first engineering colleges in
              India and South Asia. Thus, the Institute is foundational in
              encouraging and fostering the field of higher education and
              research within India.
              <br /> <br />
              The Institute was instrumental in shaping the education and
              personality of many of our esteemed alumni who only provided
              accolades for the Institute, whether within India or outside. IIT
              Roorkee is thus a college of extreme importance worldwide, holding
              a long and humongous legacy of academic brilliance that other
              premier institutes within the country could only strive for.
              <br /> <br />
              The Pupils of IIT Roorkee excel from the academic forefront but
              possess enough talent and skills to excel in other areas, whether
              related to sports, drama, or otherwise. Thus, it is a testament to
              the Institute's focus on bringing out the best individuals or
              nurturing the area in whichever area they are talented in while
              not compromising pupils' education. The institutes will continue
              to stimulate excellence in all who likely engage with the
              Institute and thus be instrumental in country and pupil
              development.
            </>
          }
        />
      </div>
      <div className="py-8">
        <RulesComponent
          heading="Address from"
          subheading="chairman"
          paragraph={
            <>
              As we march into the new year, we march ourselves to host another
              Sangram event, which is one of the most prominent sports festivals
              in north India. The event reinforced the capability of IIT Roorkee
              to organise sports events at a large scale successfully and
              jubilantly. We as an institution understand the importance of
              sports for students in academic life, which we can acknowledge
              with the notion that IIT Roorkee continues to be on a pedestal in
              Inter IIT Sports every year. The accolades of the quality of
              research and academics of the Institute primarily drive the
              academic Institute.
              <br /> <br />
              However, it shouldn’t just strive to excel in these components
              only. The hallmark of any excellent higher educational institution
              is the ability to provide opportunities for students to develop a
              well-rounded personality, and sports are one of the important
              factors in that. The sports in themselves promote cooperation and
              brotherhood among the students, the ability to lead and the
              ability to trust in each other, and the ability to work in a group
              are few of the traits that students would take with them which
              would turn out to have a substantial use case once they leave the
              Institute in whatever career they pursue.
              <br /> <br />
              There are also many other tangible benefits of sports, especially
              in the modern 21st-century IT world where there is little reason
              primarily to go outside the premises. In some cases, the sports
              here help ensure the body is sufficiently fit and provide a fun
              outlook that rejuvenates the mind. Hence, sports drive the concept
              of a healthy mind and body easily and effectively. In a nutshell,
              the Sangram is motivated to sustain in scholars to hold in
              themselves the passion of sports, the fire of which should
              continue to sustain well once they leave the Institute. With this
              goal, it is my pleasure to serve as the host for another edition
              of Sangram.
            </>
          }
        />
      </div>
    </Layout2>
  );
};

export default AboutPage;
