import React from "react";
import PointsHeading from "./PointsHeading";
import PointsSystem from "./PointsSystem";

const Points = ({ gender }) => {
  const sportsmen = [
    {
      sportsName: "Athletics",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants:"12 2 events per person",
    },
    {
      sportsName: "Badminton",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 5,
    },
    {
      sportsName: "Basketball",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 10,
    },
    {
      sportsName: "Taekwondo",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 14,
    },
    {
      sportsName: "Cricket",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 14,
    },
    {
      sportsName: "Hockey",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 15,
    },
    {
      sportsName: "Football",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 14,
    },
    {
      sportsName: "Tennis",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 4,
    },
    {
      sportsName: "Table Tennis",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 4,
    },
    {
      sportsName: "Volleyball",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 10,
    },
    {
      sportsName: "Weightlifting",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 10,
    },
    {
      sportsName: "Squash",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 4,
    },
    {
        sportsName: "Swimming",
        first: 10,
        second: 6,
        third: 4,
        fourth: 1,
        participants: 13,
      },
  ];
  const sportswomen = [
    {
      sportsName: "Athletics",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 8,
    },
    {
      sportsName: "Badminton",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 3,
    },
    {
      sportsName: "Basketball",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 10,
    },
    {
      sportsName: "Taekwondo",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 6,
    },
    {
      sportsName: "Football",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: "10 (7-playing)",
    },
    {
      sportsName: "Tennis",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 3,
    },
    {
      sportsName: "Table Tennis",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 3,
    },
    {
      sportsName: "Volleyball",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 10,
    },
    {
      sportsName: "Squash",
      first: 10,
      second: 6,
      third: 4,
      fourth: 1,
      participants: 3,
    },
    {
        sportsName: "Swimming",
        first: 10,
        second: 6,
        third: 4,
        fourth: 1,
        participants: 4,
      },
  ];
  const sports = gender === "men" ? sportsmen : sportswomen;
  return (
    <div className="w-[90%] mx-auto py-16">
      <div className="text-red-600 font-bungee-shade lg:text-7xl md:text-6xl text-5xl font-normal mb-4">
        Points ({" "}
        <span className="text-red-600 font-bungee-shade lg:text-4xl md:text-3xl text-2xl font-normal">
          {gender}
        </span>{" "}
        )
      </div>
      <PointsHeading participants={gender} />
      {sports.map((sport, index) => (
        <PointsSystem
          key={index}
          sportsName={sport.sportsName}
          first={sport.first}
          second={sport.second}
          third={sport.third}
          fourth={sport.fourth}
          participants={sport.participants}
        />
      ))}
    </div>
  );
};

export default Points;
