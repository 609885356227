import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assests";
import { CgMenuRight } from "react-icons/cg";
import { RxCrossCircled } from "react-icons/rx";

const Navbar = () => {
  const links = [
    { id: 1, name: "Home", path: "/" },
    { id: 2, name: "About", path: "/about" },
    { id: 3, name: "Events", path: "/events" },
    { id: 5, name: "Sponsors", path: "/sponsors" },
    { id: 6, name: "Team", path: "/team" },
    { id: 8, name: "Rules", path: "/rules" },
  ];

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log("clicked");
  };

  return (
    <div className="flex justify-between items-center bg-black w-full lg:px-24 md:px-12 px-8 py-4">
      <div>
        <img
          src={logo}
          alt=""
          className="md:w-[92px] w-[60px] md:h-[95px] h-[70px] bg-white rounded-full"
        />
      </div>
      <div className="md:block hidden">
        <ul className="flex gap-4 justify-between">
          {links.map((link) => (
            <li key={link.id} className="text-white text-l cursor-pointer">
              <Link to={link.path}>{link.name}</Link>
            </li>
          ))}
          <li className="text-white text-l cursor-pointer">
            <a href="#contact">Contact Us</a>
          </li>
          <li className="text-white text-l cursor-pointer">
            <a href="https://drive.google.com/drive/folders/1DHg8KdbYfpk1XYBfJZ_vF-a1TD5DVfJZ?usp=drive_link">Brochure</a>
          </li>
          <li className="text-white text-l cursor-pointer">
            <a href="https://forms.gle/QC4UfbUnEj3Tk34C7">Register</a>
          </li>
        </ul>
      </div>
      <div className="md:hidden block relative" onClick={toggleMenu}>
        <CgMenuRight className="w-10 h-10 text-white cursor-pointer" />
      </div>

      {menuOpen && (
        <div className="md:hidden z-20 absolute top-10  right-0 bg-gray-800 h-full py-4 transition-all duration-300 ease-in-out">
          <RxCrossCircled
            className="w-8 h-8 ml-2  text-white"
            onClick={toggleMenu}
          />
          <ul className="flex flex-col p-4">
            {links.map((link) => (
              <li key={link.id} className="text-white text-xl my-2">
                <Link to={link.path} onClick={toggleMenu}>
                  {link.name}
                </Link>
              </li>
            ))}
            <li
              className="text-white text-xl cursor-pointer"
              onClick={toggleMenu}
            >
              <a href="#contact">Contact Us</a>
            </li>
            <li
              className="text-white text-xl cursor-pointer"
              onClick={toggleMenu}
            >
              <a href="https://drive.google.com/drive/folders/1DHg8KdbYfpk1XYBfJZ_vF-a1TD5DVfJZ?usp=drive_link">Brochure</a>
            </li>
            <li
              className="text-white text-xl cursor-pointer"
              onClick={toggleMenu}
            >
              <a href="https://forms.gle/QC4UfbUnEj3Tk34C7">Register</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
