import React from "react";
import { frame } from "../../assests";
const Banner = () => {
  return (
    <div className="flex gap-4 w-[90%] mx-auto pt-8">
      {/* left banner */}
      <div className="flex flex-col md:w-[50%] w-full space-y-0 p-8">
        <h1 className="text-white  lg:text-8xl md:text-6xl text-4xl  font-bungee">Sangram</h1>
        <h1 className="text-red-600 lg:text-8xl md:text-6xl text-4xl font-bungee-shade ">2024</h1>
        <h1 className="text-[#FBFBFB] lg:text-2xl text-xl pt-2 font-cabin ">
          AN IIT ROORKEE'S SPORTS MEET
        </h1>
      </div>
      {/* right banner */}
      <div className="h-full w-[50%] md:block hidden ">
        <img src={frame} alt="" className="min-h-[10rem] h-[28rem]" />
      </div>
    </div>
  );
};

export default Banner;
