import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import ImageSlider from "./ImageSlider";
import "./slider.css";
import {
  volleyballicon,
  hockeyicon,
  basketballicon,
//   marathon,
  cricketicon,
  footballicon,
  weighticon,
  chessicon,
  taekwondoicon,
  badmintonicon,
  tabletennisicon,
//   esportsicon,
  tennisicon,
  athleticsicon,
  swimmingicon,
  squashicon,
} from "../../assests";

const Carousel = () => {
  const games = [
    { src: volleyballicon, description: "VolleyBall", path: "/volleyball" },
    { src: cricketicon, description: "Cricket", path: "/cricket" },
    { src: basketballicon, description: "Basketball", path: "/basketball" },
    // { src: marathon, description: "Marathon", path: "/marathon" },
    { src: hockeyicon, description: "Hockey", path: "/hockey" },
    { src: footballicon, description: "Football", path: "/football" },
    { src: weighticon, description: "WeightLifting", path: "/weightlifting" },
    { src: chessicon, description: "Chess", path: "/chess" },
    { src: taekwondoicon, description: "Taekwondo", path: "/taekwondo" },
    { src: badmintonicon, description: "Badminton", path: "/badminton" },
    { src: tabletennisicon, description: "Table Tennis", path: "/tabletennis" },
    // {
    //   src: esportsicon,
    //   description: "Esports",
    //   path: "https://docs.google.com/forms/d/e/1FAIpQLSemzehkbOTPboeXyc7gFgxVpMe0z7IYGmXa5x18kEBgJ1_JlA/viewform",
    // },
    { src: tennisicon, description: "Tennis", path: "/tennis" },
    { src: athleticsicon, description: "Athletics", path: "/athletics" },
    { src: swimmingicon, description: "Swimming", path: "/swimming" },
    { src: squashicon, description: "Squash", path: "/squash" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className=" bg-red-200 bg-opacity-60 md:pb-12 p-12 pb-0  ">
      <div className="text-black font-bungee-shade lg:text-6xl md:text-5xl text-4xl font-normal">
        Events in
      </div>
      <div className="text-red-700 font-bungee lg:text-7xl md:text-6xl text-5xl font-normal capitalize">
        SANGRAM
      </div>
      <Slider {...settings} className=" md:my-12 my-0 p-4">
        {games.map((game, index) => (
          <Link to={game.path} key={index}>
            <ImageSlider src={game.src} description={game.description} />
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
