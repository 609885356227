import React from "react";
import Layout from "../components/layout/Layout";
import Banner from "../components/main/Banner";
import LeftAbout from "../components/about/LeftAbout";
import RightAbout from "../components/about/RightAbout";
import Carousel from "../components/events/Event";
import { useEffect , useState } from "react";
import SkeletonLoadingPage from "../components/Loading/Skeleton";
const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <SkeletonLoadingPage />;
  }
  return (
    <Layout>
      <div>
        {/* navbar section + banner */}
        <section className="bg-black w-full">
          <Banner />
        </section>
        {/* section 1 ends */}
        {/* about section starts */}
        <section className="bg-white flex justify-between py-8 relative overflow-hidden">
          <LeftAbout />
          <RightAbout />
        </section>
        {/* about section ends */}

        {/* events section */}
        <section>
          <Carousel />
        </section>
      </div>
    </Layout>
  );
};

export default Home;
