import React from "react";
import Layout2 from "../components/layout/EventsLayout";
import TeamMemberCard from "../components/team/TeamCard";
import {
//   aadit,
//   aditya,
//   aditya2,
//   aditya3,
//   akshat,
//   ayush,
//   bharat,
//   deepak,
//   deepak2,
//   dewanshu,
//   dipanshi,
//   golu,
//   harsh,
//   hemant,
//   karan,
//   keshav,
//   kush,
//   pratham,
//   rajat,
//   rupesh,
//   sahaj,
//   shruti,
//   shubhanshu,
//   siddharth,
//   smit,
//   vipul,
//   vishal,
//   yash,
//new team
  ankit_l,
  ashna_c,
  atal,
  harshit_c,
  karthik,
  parth_p,
  renu,
  santosh,
  vaibhav_k,
  aman_k,
  abhinav_s,
  anmol_t,
  shashwat_g,
  dheeraj_s,
} from "../assests";
import { useState, useEffect } from "react";
import SkeletonLoadingPage from "../components/Loading/Skeleton";
const teamData = [
  {
    name: "Anmol Tambi",
    position: "Head-Sponsorship",
    image: anmol_t,
  },
  {
    name: "Shashwat Garg",
    position: "Head-Sponsorship",
    image: shashwat_g,
  },
  {
    name: "Harshit Chaturvedi",
    position: "Head-Accommodation",
    image: harshit_c,
  },
  {
    name: "Santosh",
    position: "Head-Promotion",
    image: santosh,
  },
  {
    name: "Renu",
    position: "Head-Promotion",
    image: renu,
  },
  {
    name: "Atal Mishra",
    position: "Head-WebD",
    image: atal,
  },
  {
    name: "Ashna Chaudhary",
    position: "Head-Design",
    image: ashna_c,
  },
  {
    name: "Vaibhav Kavdia",
    position: "Head-Marketing",
    image: vaibhav_k,
  },
  {
    name: "Parth Porwal",
    position: "Head-Media",
    image: parth_p,
  },
//   {
//     name: "Shruti Chauhan",
//     position: "Head-Sponsorship",
//     image: shruti,
//   },
//   {
//     name: "Rupesh Kumar Mandiya",
//     position: "Head-Sponsorship",
//     image: rupesh,
//   },
//   {
//     name: "Dipanshi",
//     position: "Head-Editorial",
//     image: dipanshi,
//   },
//   {
//     name: "Kush",
//     position: "Head-Design",
//     image: kush,
//   },

//   {
//     name: "Sahajpreet Singh",
//     position: "Head-WebD",
//     image: sahaj,
//   },
];

// const teamData2 = [
//   {
//     name: "Aditya Kaushik",
//     position: "Head-Marathon",
//     image: aditya2,
//   },
//   {
//     name: "Aditya Kumar",
//     position: "Head-InformalEvents",
//     image: aditya,
//   },
//   {
//     name: "Aditya Ranjan",
//     position: "Head-Chess",
//     image: aditya3,
//   },
//   {
//     name: "Ayush Bhaskar",
//     position: "Head-Cricket",
//     image: ayush,
//   },
//   {
//     name: "Bharat Prateek",
//     position: "Head-Events",
//     image: bharat,
//   },
//   {
//     name: "Deepak Nailwal",
//     position: "Head-Basketball",
//     image: deepak,
//   },
//   {
//     name: "Golu Jatav",
//     position: "Head-Hockey",
//     image: golu,
//   },
//   {
//     name: "Deepak Sharma",
//     position: "Head-Athletics",
//     image: deepak2,
//   },
//   {
//     name: "Vipul Kalouni",
//     position: "Head-Football",
//     image: vipul,
//   },
//   {
//     name: "Rajat Kumar Suda",
//     position: "Head-Volleyball",
//     image: rajat,
//   },
//   {
//     name: "Siddarth Sharma",
//     position: "Head-WeightLifting",
//     image: siddharth,
//   },
//   {
//     name: "Smit K Motghare",
//     position: "Head-Badminton",
//     image: smit,
//   },
//   {
//     name: "Keshav Goyal",
//     position: "Head-Tennis",
//     image: keshav,
//   },
// ];
const teamData3 = [
  {
    name: "Dheeraj Sharma",
    position: "Convener",
    image: dheeraj_s,
  },
];
const teamData4 = [
  {
    name: "Abhinav Singh",
    position: "Co-Convener(Events)",
    image: abhinav_s,
  },
  {
    name: "Ankit Lal",
    position: "Co-Convener(Adm.)",
    image: ankit_l,
  },
  {
    name: "Karthik Gudipudi",
    position: "Co-Convener(Acc.)",
    image: karthik,
  },
];

const teamData5 = [
  {
    name: "Aman Kumar",
    position: "Treasurer",
    image: aman_k,
  },
//   {
//     name: "Yash Tanwar",
//     position: "Organising Secretary",
//     image: yash,
//   },
//   {
//     name: "Aadit Goel",
//     position: "Head of Events",
//     image: aadit,
//   },
];
const TeamPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <SkeletonLoadingPage />;
  }
  return (
    <Layout2>
      <div className="bg-black">
        <div className="container mx-auto py-12 px-4">
          <h1 className="text-center font-bungee lg:text-6xl md:5xl text-4xl font-normal uppercase text-white">
            Team Sangram
          </h1>
          <p>
            <div className="text-center font-cabin lg:text-lg text-sm font-normal my-8 text-white">
              The Sangram Sports Fest is the annual sports fest of IIT Roorkee
              and is held every year. Attempts have been made to make this rule
              book comprehensive. However, for any further clarifications or
              support, the Rule book of Sangram’24 should be referred to.
            </div>
          </p>
          <div>
            <div className="flex flex-col items-center w-full gap-4 justify-center">
              <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1  place-items-center gap-8">
                {teamData3.map((member, index) => (
                  <TeamMemberCard key={index} member={member} />
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center w-full gap-4 justify-center mb-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  place-items-center gap-8">
                {teamData4.map((member, index) => (
                  <TeamMemberCard key={index} member={member} />
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center w-full gap-4 justify-center mb-4">
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1  place-items-center gap-8">
                {teamData5.map((member, index) => (
                  <TeamMemberCard key={index} member={member} />
                ))}
              </div>
            </div>
            {/* <h2 className="text-white text-center font-bungee lg:text-7xl md:text-5xl text-4xl font-normal capitalize">
              Head(Non-sport)
            </h2> */}
            <div className="flex flex-col items-center w-full gap-4 justify-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  place-items-center gap-8">
                {teamData.map((member, index) => (
                  <TeamMemberCard key={index} member={member} />
                ))}
              </div>
            </div>
          </div>
          {/* <div>
            <h2 className="text-white text-center font-bungee text-7xl font-normal capitalize">
              Heads
            </h2>
            <div className="flex flex-col items-center w-full gap-4 justify-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  place-items-center gap-8">
                {teamData2.map((member, index) => (
                  <TeamMemberCard key={index} member={member} />
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Layout2>
  );
};

export default TeamPage;
