import React from "react";
import Navbar from "../main/Navbar";
import Contact from "../contact/contact";
import Footer from "../footer/footer";
import Highlights from "../highlights/Highlights";

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <main>{children}</main>
      <Highlights />
      <Contact />
      <Footer />
    </div>
  );
};

export default Layout;
