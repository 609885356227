import React from "react";

const SponsorCard = ({ sponsor , category }) => {
  const cardStyle = {
    width: "300px",
    height: "300px",
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
    border: "2px solid gray",
    padding: "10px",
  };

  return (
    <div className="my-8 flex flex-col items-center">
      <div className="flex justify-center mb-4">
        <h2 className="text-white mt-4 font-bungee lg:text-xl md:text-xl text-xl  font-normal">
          {category}
        </h2>
      </div>
      <div className="rounded-lg" style={cardStyle}>
        <img
          src={sponsor}
          alt="Sponsor"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
};

export default SponsorCard;
