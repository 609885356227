import React from "react";

const SkeletonLoadingPage = () => {
  return (
    <div className="bg-gray-200 min-h-screen flex items-center justify-center">
      <div className="max-w-4xl w-full bg-white p-8 rounded-lg shadow-lg">
        {/* Header Skeleton */}
        <div className="animate-pulse mb-6">
          <div className="bg-gray-300 h-10 w-1/4 mb-4"></div>
          <div className="bg-gray-300 h-8 w-3/4"></div>
        </div>

        {/* Main Content Skeleton */}
        <div className="flex gap-8">
          {/* Left Content Skeleton */}
          <div className="w-1/2">
            <div className="bg-gray-300 h-32 mb-4"></div>
            <div className="bg-gray-300 h-32"></div>
          </div>

          {/* Right Content Skeleton */}
          <div className="w-1/2">
            <div className="bg-gray-300 h-64 mb-4"></div>
            <div className="bg-gray-300 h-64"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoadingPage;
