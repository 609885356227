import React from "react";
import ContactCard from "./ContactCard";

const Contact = () => {
  return (
    <div className="w-[90%] mx-auto flex flex-col gap-4 my-12">
      <div className="text-black font-bungee-shade lg:text-6xl md:text-5xl text-4xl font-normal">
        Contact
      </div>
      <div className="text-red-600 font-bungee lg:text-7xl md:text-6xl text-5xl font-normal capitalize">
        TEAM SANGRAM
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4">
        <ContactCard
          name="Dheeraj Sharma"
          designation="Convenor"
          phoneNumber="+91 89552-00661"
        />
        <ContactCard
          name="Karthik Gudipudi"
          designation="Co-Convenor"
          phoneNumber="+91 96296-11518"
        />
        <ContactCard
          name="Ankit Lal"
          designation="Co-Convenor"
          phoneNumber="+91 99587-18768"
        />
      </div>
    </div>
  );
};

export default Contact;