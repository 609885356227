import React from "react";
import { Link } from "react-router-dom";

const LeftAbout = () => {
  return (
    <div className="flex flex-col md:w-[50%] md:ml-20 w-[90%] mx-auto pt-8">
      <div className="text-red-600 lg:text-6xl md:text-5xl text-4xl font-bungee-shade font-normal">
        About
      </div>
      <div className="text-red-700 lg:text-7xl md:text-6xl text-5xl font-bungee font-normal  capitalize">
        Sangram
      </div>
      <div className="flex flex-col gap-20 py-8">
        <div className="text-red-800 text-xl font-cabin font-normal leading-normal capitalize">
          Sangram, a flagship three-day sports extravaganza of IIT Roorkee,
          strives to celebrate sports and provide a healthy environment to
          entertain and showcase your talent in sports in front of large numbers
          of pupils from some of the country's premier institutes.
        </div>
        {/* <div className="text-red-800 text-xl font-cabin font-medium leading-normal capitalize">
          The festival enjoys the attendance of many people from IITs, NITs and
          other premier government and private colleges. The event is a gorgeous
          festival where people of different backgrounds from all around the
          college come to support and take part in occasions to support their
          fellow members, their teams and colleagues, and sometimes their
          friends too.
        </div> */}
        <div className="text-red-800 text-xl font-cabin font-normal leading-normal capitalize">
          The event inculcates togetherness and acknowledges the spirit of
          sports and its importance in our lives. Engagement in Sangram can only
          be experienced, and its exuberance can't be reduced to mere words; the
          memories it brings about are enough to last a lifetime.
        </div>
        { /*<div className="text-red-800 text-base font-cabin font-normal leading-normal capitalize">
          Sangram also hosts many casual events like sports quizzes or marathon
          events, underscoring that it's not all about the physical sports
          events but also about enjoying and having the time of your life in
          general, whatever your preference.
        </div> */}
      </div>
      <div className="rounded-full w-max px-8 py-3  bg-red-600">
        <Link to="/about">
          <button className=" text-white text-base font-cabin italic font-semibold leading-normal">
            Know More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LeftAbout;
