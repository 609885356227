import React from "react";

const RulesComponent = ({ heading, subheading, paragraph }) => {
  return (
    <div className="w-[90%] mx-auto my-12">
      <div className="text-red-600  font-bungee-shade lg:text-7xl md:text-5xl text-4xl font-normal">
        {heading}
      </div>
      <div className="text-red-800 font-bungee lg:text-7xl md:text-5xl text-4xl font-normal capitalize">
        {subheading}
      </div>
      <div className="g:w-[80%] w-[90%] mt-4">
        <p className="text-red-900 font-cabin text-base font-normal capitalize">
          {paragraph}
        </p>
      </div>
    </div>
  );
};

export default RulesComponent;
