import React from "react";
import { useState, useEffect } from "react";

const TeamMemberCard = ({ member }) => {
  console.log(member);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = width <= 768;

  const cardStyle = {
    width: isMobile ? "250px" : "300px",
    height: isMobile ? "250px" : "300px",
    borderRadius: "40px",
    position: "relative",
    overflow: "hidden",
  };

  return (
    <div className="lg:mt-8 mt-4 lg:mb-12 mb-4">
      <div
        className=" cursor-pointer rounded-2xl overflow-hidden"
        style={cardStyle}
      >
        <img
          src={member.image}
          alt={member.name}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="text-center mt-2">
        <h3 className="text-white text-center font-cabin text-[20px] font-bold capitalize">
          {member.name}
        </h3>
        <p className="text-white text-center font-cabin text-[20px] font-normal capitalize">
          {member.position}
        </p>
      </div>
    </div>
  );
};

export default TeamMemberCard;
