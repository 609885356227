import React from "react";

const PointsHeading = ({ participants }) => {
  return (
    <>
      <div className="flex justify-between gap-4 items-center ">
        <div className="text-black lg:w-[300px] w-[200px]  lg:h-[100px] p-1.5 rounded-lg flex justify-center items-center bg-white font-cabin lg:text-lg md:text-md text-sm font-bold capitalize">
          events
        </div>
        <div className="flex flex-col md:w-[400px] w-[150px] md:gap-4 gap-2 ">
          <div className="bg-white w-full text-center py-2 text-black font-cabin lg:text-lg md:text-md text-sm font-bold capitalize rounded-lg">
            Points
          </div>
          <div className="flex flex-row w-full gap-3 justify-between">
            <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
              1
            </div>
            <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
              2
            </div>
            <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
              3
            </div>
            <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
              4
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 lg:w-[300px] md:w-[200px] w-[150px] ">
          <div className="text-black rounded-lg bg-white py-2 text-center lg:text-lg md:text-md text-[.55rem] font-bold capitalize px-2">
            max no of participants
          </div>
          <div className="w-full bg-white lg:py-3 md:py-2 py-1 rounded-lg md:block hidden text-white">hi</div>
        </div>
      </div>
      <div className="w-full bg-white text-black font-cabin lg:text-xl md:text-md text-sm font-bold capitalize flex justify-center items-center lg:py-3 md:py-2 py-1 mt-4 rounded-lg">
        {participants}
      </div>
    </>
  );
};

export default PointsHeading;
