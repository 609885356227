import React from 'react'
import { football } from '../../assests'
import { crickettemplate } from '../../assests'
import { basketballtemplate } from '../../assests'

const RightAbout = () => {
  return (
    <div className="hidden w-[45%] ml-10 md:flex justify-around items-center flex-col">
        <img src={crickettemplate} alt="" className="h-[40%]" />
        <img src={basketballtemplate} alt="" className="h-[40%]"/>
    </div>
  )
}

export default RightAbout