import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import SponsorsPage from "./components/sponsors/Sponsors";
import AboutPage from "./pages/AboutPage";
import Rules from "./pages/Rules";
import TeamPage from "./pages/TeamPage";
import SkeletonLoadingPage from "./components/Loading/Skeleton";

// Lazy-loaded components
const EventPage = lazy(() => import("./pages/EventPage"));
const BasketaballPage = lazy(() => import("./pages/sports/basketball"));
const Football = lazy(() => import("./pages/sports/football"));
const VolleyBall = lazy(() => import("./pages/sports/volleyball"));
const Cricket = lazy(() => import("./pages/sports/cricket"));
const Chess = lazy(() => import("./pages/sports/chess"));
const Tennis = lazy(() => import("./pages/sports/tennis"));
const TableTennis = lazy(() => import("./pages/sports/tabletennis"));
const Taekwondo = lazy(() => import("./pages/sports/taekwondo"));
const Hockey = lazy(() => import("./pages/sports/hockey"));
const WeightLifting = lazy(() => import("./pages/sports/weightlifing"));
const Athletics = lazy(() => import("./pages/sports/athletics"));
const Marathon = lazy(() => import("./pages/sports/marathon"));
const Snooker = lazy(() => import("./pages/sports/snooker"));
const Badminton = lazy(() => import("./pages/sports/badminton"));
const Swimming = lazy(() => import("./pages/sports/swimming"));
const Squash = lazy(() => import("./pages/sports/squash"));

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/events" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><EventPage /></Suspense>} />
      <Route path="/sponsors" element={<SponsorsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/team" element={<TeamPage />} />
      {/* for all pages about */}
      <Route path="/basketball" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><BasketaballPage /></Suspense>} />
      <Route path="/football" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Football /></Suspense>} />
      <Route path="/volleyball" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><VolleyBall /></Suspense>} />
      <Route path="/cricket" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Cricket /></Suspense>} />
      <Route path="/chess" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Chess /></Suspense>} />
      <Route path="/tennis" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Tennis /></Suspense>} />
      <Route path="/tabletennis" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><TableTennis /></Suspense>} />
      <Route path="/taekwondo" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Taekwondo /></Suspense>} />
      <Route path="/hockey" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Hockey /></Suspense>} />
      <Route path="/weightlifting" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><WeightLifting /></Suspense>} />
      <Route path="/marathon" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Marathon /></Suspense>} />
      <Route path="/athletics" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Athletics /></Suspense>} />
      <Route path="/snooker" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Snooker /></Suspense>} />
      <Route path="/badminton" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Badminton /></Suspense>} />
      <Route path="/squash" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Squash /></Suspense>} />
      <Route path="/swimming" element={<Suspense fallback={<div><SkeletonLoadingPage /></div>}><Swimming /></Suspense>} />
    </Routes>
  );
}

export default App;
