import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HighlightCard from "./HighlightCard";
import { athleticsH, badmintonH, basketballH, cricketH, highlights, squashH, taekwondoH, ttH, weightH } from "../../assests/index";


const sportsData = [
  { imageUrl: highlights, sportsName: "Football" },
  { imageUrl: weightH, sportsName: "WeightLifting" },
  { imageUrl: squashH, sportsName: "squash" },
  { imageUrl: athleticsH, sportsName: "athletics" },
  { imageUrl: badmintonH, sportsName: "badminton" },
  { imageUrl: cricketH, sportsName: "Cricket" },
  { imageUrl: taekwondoH, sportsName: "Taekwondo" },
  { imageUrl: ttH, sportsName: "Table Tennis" },
  { imageUrl: basketballH, sportsName: "basketball" },
];

const Highlights = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-[#ffc5b5] bg-opacity-90 p-12 h-auto">
      <div className="text-black font-bungee-shade lg:text-6xl md:text-4xl text-3xl font-normal">
        Highlights of
      </div>
      <div className="text-red-600 font-bungee lg:text-7xl md:text-5xl text-4xl font-normal capitalize">
        SANGRAM
      </div>
      <Slider {...settings} className="gap-4 my-12">
        {sportsData.map((sport, index) => (
          <HighlightCard
            key={index}
            imageUrl={sport.imageUrl}
            sportsName={sport.sportsName}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Highlights;
