import React from "react";
import Layout2 from "../layout/EventsLayout";
import SponsorCard from "./SponsorsCard";
import {
  alumni,
  berado,
  blaze,
  bull,
  gym,
  master,
  perfume,
  play,
  victor,
  yoga,
} from "../../assests";

const sponsorsData = [
  {
    category: "sports event partner",
    url: play,
    onclick_url: "https://play.decathlon.in/event-details/Decathlon-Sangram24-IIT-Roorkee/6e84cb52-e6a9-11ee-91dd-530e58ee4596?utm_source=sharebutton&utm_medium=decathlon_play_partner_app&utm_campaign=externalshare"
  },
  {
    category: "Perfume partner",
    url: perfume,
    onclick_url: ""
  },
  {
    category: "Alumni partner",
    url: alumni,
    onclick_url:"https://www.iitraa.in/Default1?aspxerrorpath=/default.aspx"
  },
  {
    category: "grooming partner",
    url: berado,
    onclick_url:"https://beardo.in/"
  },
  {
    category: "fitness partner",
    url: blaze,
    onclick_url: "https://www.muscleblaze.com/?gclid=CjwKCAjwh4-wBhB3EiwAeJsppIjmYkOxrxtagHdtp6q4Jm07abh4tAM_FiaIi7l8B8KnEWfHULKSNhoCfVIQAvD_BwE&utm_source=google&utm_medium=cpc&utm_campaign=_MB_B%7CMUSCLEBLAZE_CT%7CSEARCH_MT%7CALL_SC%7CALL_AUD%7CNULL&gad_source=1&gbraid=0AAAAACv4M5pVQ2DkSIIDe0hqV418yvMIN&ef_id=ZgRvaQAABdy0dGtT:20240327191137:s"
  },
  {
    category: "nutritionpartner",
    url: yoga,
    onclick_url: "https://www.yogabars.in/"
  },
  {
    category: "badminton event partner",
    url: victor,
    onclick_url: "https://www.victorsport.in/"
  },
  {
    category: "gym partner",
    url: gym,
    onclick_url: "https://www.facebook.com/p/The-Fit-Camp-Gym-Roorkee-100063684591232/"
  },
  {
    category: "cat partner",
    url: bull,
    onclick_url: "https://www.hitbullseye.com/"
  },
  {
    category: "gate partner",
    url: master,
    onclick_url: "https://iesmaster.org/"
  },
];

const SponsorsPage = () => {
  return (
    <Layout2>
      <div className="bg-black">
        <div className="container mx-auto py-12 px-4">
          <h1 className="text-center font-bungee lg:text-6xl md:5xl text-4xl font-normal uppercase text-white">
            Sponsors of Sangram
          </h1>
          <p>
            <div className="text-center font-cabin lg:text-lg text-sm font-normal my-8 text-white">
              The Sangram Sports Fest is the annual sports fest of IIT Roorkee
              and is held every year. Attempts have been made to make this rule
              book comprehensive. However, for any further clarifications or
              support, the Rule book of Sangram’24 should be referred to.
            </div>
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-8">
            {sponsorsData.map((sponsor, index) => (
              <a href={sponsor.onclick_url}>
              <SponsorCard
                key={index}
                sponsor={sponsor.url}
                category={sponsor.category}
              />
              </a>
            ))}
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default SponsorsPage;
