import React from "react";

const PointsSystem = ({
  sportsName,
  first,
  second,
  third,
  fourth,
  participants,
}) => {
  return (
    <div className="flex justify-between items-center my-4 gap-4">
      <div className="text-black lg:w-[300px] w-[200px] py-2 rounded-lg flex justify-center items-center bg-white font-cabin lg:text-lg md:text-md text-[.55rem] font-bold capitalize">
        {sportsName}
      </div>
      <div className="flex flex-col lg:w-[400px] md:w-[300px] w-[150px] gap-4 ">
        <div className="flex flex-row w-full gap-3 justify-between">
          <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-lg text-sm font-bold capitalize">
            {first}
          </div>
          <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-lg text-sm font-bold capitalize">
            {second}
          </div>
          <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-lg text-sm font-bold capitalize">
            {third}
          </div>
          <div className="bg-white lg:py-3 md:py-2 py-1 lg:px-10 md:px-8 px-2 rounded-lg text-black text-center font-cabin lg:text-xl md:text-lg text-sm font-bold capitalize">
            {fourth}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:w-[300px] md:w-[200px] w-[150px] ">
        <div className="text-black rounded-lg bg-white py-2 px-2 text-center lg:text-lg md:text-md text-sm font-bold capitalize">
          {participants}
        </div>
      </div>
    </div>
  );
};

export default PointsSystem;
