import React from "react";

const ContactCard = ({ name, designation, phoneNumber, email }) => {
  return (
    <div className="flex flex-col">
      <div className="text-red-900 font-cabin text-lg font-bold capitalize">
        {name}
      </div>
      <div className="text-red-900 font-cabin text-lg font-normal capitalize">
        {designation}
      </div>

      <div className="text-red-900 font-cabin text-lg italic font-normal capitalize">
        {phoneNumber}
      </div>
      <div className="text-red-900 font-cabin text-lg italic font-normal capitalize">
        {email}
      </div>
    </div>
  );
};

export default ContactCard;
