import React from "react";
import Layout2 from "../components/layout/EventsLayout";
import Points from "../components/Points/Points";
import RulesComponent from "../components/rules/Rules";
import { useState, useEffect } from "react";
import SkeletonLoadingPage from "../components/Loading/Skeleton";
const Rules = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <SkeletonLoadingPage />;
  }
  return (
    <Layout2>
      <div className=" py-8">
        <RulesComponent
          heading="rules"
          subheading="and regulations"
          paragraph=" The Sangram Sports Fest is the annual sports fest of IIT Roorkee and
          is held every year. Attempts have been made to make this rule book
          comprehensive. However, for any further clarifications or support, the
          Rule book of Sangram’24 should be referred to."
        />
      </div>
      <div className="bg-[#E7E7E7] py-8">
        <RulesComponent
          heading="aims"
          subheading="and objective"
          paragraph={
            <>
              The primary objective of the Sangram Sports Fest shall be to
              organize sports competitions in a recognized event to encourage
              sportsmanship, promote and create better harmony, understanding,
              and friendly interaction among the staff and faculty of the
              participating institutes and to foster a close association among
              them.
              <br />
              <br />
              (ii) To raise awareness about the importance of sports and games
              in the modern era.
              <br />
              <br />
              (iii) To help them learn to move skillfully and effectively not
              only in sports, games, and exercises but also in all active life
              situations.
              <br />
              <br />
              (iv) To raise the standard of sports in the country and to work
              for the development of character through sports among the staff
              and faculty of the various institutes.
              <br />
              <br />
              (v) To discuss matters related to the organization of the sports
              and such other activities as may be conducive to the aims and
              objectives of the Sangram Sports Fest, as mentioned above.
            </>
          }
        />
      </div>
      <div>
        <RulesComponent
          heading="Tournament"
          subheading=""
          paragraph={
            <>
              The Sangram Sports Fest is the annual sports fest of IIT Roorkee
              and is held every year. Attempts have been made to make this rule
              book comprehensive. However, for any further clarifications or
              support, the Rule book of Sangram’24 should be referred to.
              <br />
              <br />
              1. It should be noted that each institute shall participate as a
              single team for each event.
              <br />
              <br />
              2. The maximum number of certificates that can be awarded will not
              exceed the maximum number of participants allowed in that event.
              <br />
              <br />
              3. Every institute shall submit a list of players for each
              sport/game, not exceeding the number allowed in Table-3.
              <br />
              <br />
              4. Any team refusing to play a match without proper justification
              will be eliminated from the competition, and the results of the
              matches already played by such a team will be canceled. All such
              cases will be referred to the sports board. The particular team
              may be debarred from participation for the next year.
              <br />
              <br />
              5. The Sangram Sports Fest shall consist of all the sports events
              that are mentioned in the table – 3 and table
              <br />
              <br />
              6. The points secured by each institute in various events will be
              added separately. The institute that secures the highest points
              will be awarded the general championship. In case of a tie in a
              particular sport / game, table 3 and table 5 will be used to
              compute the points. In case of a tie in for the overall
              Championship, the maximum number of first position (Gold) secured
              by a team will be taken into account for deciding the tie for the
              first place in the overall Championship and so on.
              <br />
              <br />
              7. The Rules/ Regulations that are adopted by the federation of all
              the games and events up to the first staff sports board meeting
              shall be strictly followed in that particular Sangram Sports Fest.
              Amendments, if any, must be accepted by the majority of members
              present in the pre-meet board
            </>
          }
        />
      </div>
      <div className="bg-[#E7E7E7]">
        <Points gender="men" />
      </div>
      <div className="bg-[#E8D3D3]">
        <Points gender="womens" />
      </div>
      <div className="w-[90%] mx-auto py-12">
        <div className="text-red-600 font-bungee-shade lg:text-7xl md:text-[5xl] text-4xl font-normal mb-4">
          Points Details
        </div>
        <div className="flex justify-between gap-4 items-center ">
          <div className="text-black lg:w-[300px] md:w-[200px] w-[100px] py-2 rounded-lg flex justify-center items-center bg-[#E8D3D3] font-cabin lg:text-lg md:text-md text-[.55rem] font-bold capitalize">
            events
          </div>
          <div className="flex flex-col lg:w-[400px] md:w-[200px] w-[140px] gap-4 ">
            <div className="bg-[#E8D3D3] lg:w-[400px] md:w-[200px] w-[140px] text-center py-2 text-black font-cabin text-lg font-bold capitalize rounded-lg">
              Points
            </div>
            <div className="flex flex-row lg:w-[400px] md:w-[200px] w-[140px] gap-3 justify-between">
              <div className="bg-[#E8D3D3] md:py-3 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                1
              </div>
              <div className="bg-[#E8D3D3] md:py-3 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                2
              </div>
              <div className="bg-[#E8D3D3] md:py-3 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                3
              </div>
              <div className="bg-[#E8D3D3] md:py-3 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                4
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:w-[300px] md:w-[200px] w-[150px] ">
            <div className="text-black rounded-lg bg-[#E8D3D3] py-2 text-center lg:text-lg md:text-md text-[.55rem] font-bold capitalize">
              max no of participants
            </div>
            <div className="w-full lg:block hidden bg-[#E8D3D3] text-[#E8D3D3] md:py-3 py-1 rounded-lg">
                    hi
            </div>
          </div>
        </div>
        <div className="w-full bg-[#E8D3D3] text-black font-cabin lg:text-xl md:text-md text-sm font-bold capitalize flex justify-center items-center md:py-3 py-1 mt-4 rounded-lg">
  3       neutral
        </div>
        <div className="flex justify-between items-center my-4 gap-4">
          <div className="text-black lg:w-[300px] md:w-[200px] w-[100px] py-2 rounded-lg flex justify-center items-center bg-[#E8D3D3] font-cabin lg:text-lg md:text-md text-[.55rem] font-bold capitalize">
            Chess
          </div>
          <div className="flex flex-col lg:w-[400px] md:w-[200px] w-[140px] gap-4 ">
            <div className="flex flex-row w-full gap-3 justify-between">
              <div className="bg-[#E8D3D3] md:py-2 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                10
              </div>
              <div className="bg-[#E8D3D3] md:py-2 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                6
              </div>
              <div className="bg-[#E8D3D3] md:py-2 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                4
              </div>
              <div className="bg-[#E8D3D3] md:py-2 py-1 lg:px-10 md:px-8 px-2.5 rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                1
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 lg:w-[300px] md:w-[200x] w-[150px] ">
            <div className="text-black rounded-lg bg-[#E8D3D3] py-2 text-center lg:text-lg md:text-md text-sm font-bold capitalize">
              5
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#E7E7E7]">
        <div className="w-[90%] mx-auto py-12">
          <div className="text-red-600 font-bungee-shade lg:text-7xl md:text-6xl text-5xl font-normal mb-4">
            Points
          </div>
          <div className="grid md:grid-cols-3 grid-cols-3 gap-8 md:gap-4">
            <div className="text-black lg:w-[300px] md:w-[200px] w-[100px] lg:h-[100px] h-[50px] rounded-lg flex justify-center items-center bg-white font-cabin lg:text-lg md:text-md text-sm font-bold capitalize">
              points tied for
            </div>
            <div className="flex flex-col lg:w-[400px] md:w-[300px] w-[150px] gap-4 ">
              <div className="bg-white w-full text-center py-2 text-black font-cabin text-lg font-bold capitalize rounded-lg">
                No. of Team Tied (Athletics)
              </div>
              <div className="grid grid-cols-2  gap-4 ">
                <div className="flex flex-row lg:w-[400px] md:w-[300px] w-[150px] gap-3 justify-between">
                  <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] l py-2 text-center rounded-lg text-black flex justify-center items-center font-cabin  lg:text-xl md:text-md text-sm font-bold capitalize">
                    2
                  </div>
                  <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] flex justify-center items-center rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                    3
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-3 gap-8 md:gap-4 my-4">
            <div className="text-black lg:w-[300px] md:w-[200px] w-[100px] py-2 rounded-lg flex justify-center items-center bg-white font-cabin lg:text-lg md:text-md text-sm font-bold capitalize">
              I
            </div>
            <div className="grid grid-cols-2  gap-8 md:gap-4 ">
              <div className="flex flex-row lg:w-[400px] md:w-[300px] w-[150px] gap-3 justify-between">
                <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] text-center rounded-lg text-black flex justify-center items-center font-cabin  lg:lg:text-xl md:text-md text-sm md:text-lg text-md font-bold capitalize">
                  8.0
                </div>
                <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] flex justify-center items-center rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                  6.0
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 grid-cols-3 gap-8 md:gap-4 my-4">
            <div className="text-black lg:w-[300px] md:w-[200px] w-[100px] py-2 rounded-lg flex justify-center items-center bg-white font-cabin lg:text-lg md:text-md text-sm font-bold capitalize">
              II
            </div>
            <div className="grid grid-cols-2 gap-8 md:gap-4 ">
              <div className="flex flex-row lg:w-[400px] md:w-[300px] w-[150px] gap-3 justify-between">
                <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] text-center rounded-lg text-black flex justify-center items-center font-cabin  lg:lg:text-xl md:text-md text-sm md:text-lg text-md font-bold capitalize">
                  5.0
                </div>
                <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] flex justify-center items-center rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                  4.0
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-3  gap-8 md:gap-4 my-4">
            <div className="text-black lg:w-[300px] md:w-[200px] w-[100px] py-2 rounded-lg flex justify-center items-center bg-white font-cabin lg:text-lg md:text-md text-sm font-bold capitalize">
              III
            </div>
            <div className="grid grid-cols-2  gap-4 ">
              <div className="flex flex-row lg:w-[400px] md:w-[300px] w-[150px] gap-3 justify-between">
                <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] text-center rounded-lg text-black flex justify-center items-center font-cabin  lg:lg:text-xl md:text-md text-sm md:text-lg text-md font-bold capitalize">
                  3.0
                </div>
                <div className="bg-white lg:w-[180px] md:w-[140px] w-[100px] flex justify-center items-center rounded-lg text-black text-center font-cabin lg:text-xl md:text-md text-sm font-bold capitalize">
                  2.0
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[90%] py-8  mx-auto ">
          <p className="text-red-800 font-cabin text-base font-normal capitalize">
            8. The institute securing the highest points in the Men’s events will
            be awarded the Men’s Championship and likewise for Women. <br /> 9.
            In case of a tie for the Men/ Women/ Overall Championship, the team
            having the most number of first positions (Gold) in the team events
            will be taken into account for deciding the tie for first place in
            the Championship. Along with the above-mentioned sports, points
            secured in the March Past will be counted for the Overall
            Championship. <br /> 10. The host institute shall award all the
            trophies of the Sangram’24. <br />
            11. 400 Mtr- Women and 100 Mtr-Men (55 years and above) are
            non-point events.
          </p>
        </div>
      </div>
      <div className="bg-[#E8d3d3] py-8">
        <RulesComponent
          heading="trophies"
          subheading="and certificates"
          paragraph={
            <>
              1. In team games, certificates of merit and medals medal shall be
              awarded to the winner, runners up, and the second runners up of
              each game, signed by the chairman, convener, and the organizing
              secretary of the Sangram 2024. <br />
              2. In individual events like athletics, certificates shall be
              awarded up to the third position holder. In the case of athletics,
              the best athlete (Men and Women) will also be awarded. <br />
              3. Only the IIT Roorkee Sports Board is authorized to award
              certificates to the participants in the Sangram’24. <br />
              4. General Championship Trophy will be provided up to the
              third-place holder for both men and women.
            </>
          }
        />
      </div>

      <div className="py-8">
        <RulesComponent
          heading="Conduct/"
          subheading="protest"
          paragraph={
            <>
              1. The decision of the referees will be final and binding, and no
              protests shall be entertained. Therefore, any team that leaves the
              field of play as a mark of protest and concedes a walk-over shall
              be deemed to have lost the fixture and will be eliminated from the
              event. Only the remaining teams will be considered as participating
              teams in that event. <br /> <br /> 2. Any protest against the
              decision of an umpire/referee on a point of rule, and any other
              protest in connection with the concerned event, shall be written
              along with a protest fee of Rs.500 addressed to the Jury of
              Appeal, IIT Roorkee and must reach the Organizing Secretary or any
              member of the Board of the Host Institute within an hour of the
              conclusion of the concerned game duly signed by the Officials with
              name & designation of that particular Institute. In case of events
              being conducted outside, the protest in writing shall be handed
              over to the Jury of Appeal within an hour of arrival. <br />{" "}
              <br /> 3. A team/competitor shall be permitted a grace period of 15
              minutes from the announced timing of the event and then a walk-over
              shall be awarded unless genuine reasons beyond his/ her control
              are presented and accepted by the Board.
            </>
          }
        />
      </div>
      <div className="bg-[#e8d3d3] py-8">
        <RulesComponent
          heading="disruption/"
          subheading="postponement"
          paragraph={
            <>
              Any event abandoned or disrupted due to unavoidable reasons shall
              be postponed or continued as the case may be and as per rules
              specified in the current rules of the respective Federation/
              Association. In cases where the rules of Federation/ Association
              cannot be implemented, the decision will be leŌ to the Board,
              whose ruling shall be final.
            </>
          }
        />
      </div>
      <div className="py-8">
        <RulesComponent
          heading="general"
          subheading="rules"
          paragraph={
            <>
              <br />
              1. The Institute participating in Squash may include 4 more Male
              members in the contingents, specifically for Squash and no other
              game/sport. The transfer of seats from men to women and vice-versa
              is NOT permitted. <br />
              <br /> 2. The order of events shall be fixed and notified well in
              advance to all participating institute. <br />
              <br /> 3. The Sports Association of the IIT Roorkee will provide
              the latest federation rule book of all the games at the Control
              Room so that any member can refer to the rules. <br />
              <br /> 4. It will be the responsibility of the Board to provide
              the latest changes in the rules and regulations during the pre-meet
              board meeting. <br /> <br /> 5. All the participants will bring
              their ID cards and photographs. IIT-R will issue participant cards
              only to the players as mentioned at 12(a) from a particular institute
              to participate.
            </>
          }
        />
      </div>
    </Layout2>
  );
};

export default Rules;
