import React from "react";

const ImageSlider = ({ src, description }) => {
  return (
    <div className="flex flex-col items-center justify-center h-[200px] gap-4 cursor-pointer ">
      <img
        src={src}
        alt=""
        className="mx-auto w-[125px] h-[125px] text-black transition-transform  duration-300 transform hover:scale-105"></img>
      <div className="text-[black font-cabin text-2xl font-bold">
        {description}
      </div>
    </div>
  );
};

export default ImageSlider;
